import React, { useEffect, useRef, useState } from "react";
import styles from "./Email.module.css";

export function Email({ text }: { text: string }) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [textColor, setColor] = useState<string>("");

  useEffect(() => {
    const color = getComputedStyle(document.documentElement)
      .getPropertyValue("--text")
      .trim();

    setColor(color);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas) {
      const context = canvas.getContext("2d");

      if (!context) return;

      // Measure the text width
      const textMetrics = context.measureText(text);
      const textWidth = textMetrics.width;

      canvas.width = textWidth + 30;
      canvas.height = 50;

      context.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas before drawing

      context.font = "100 10px Merriweather, serif";
      context.fillStyle = textColor; // Установить цвет шрифта

      context.fillText(`${text}`, 10, 30);
    }
  }, [text, textColor]);

  return (
    <canvas
      className={styles.email_overlay}
      ref={canvasRef}
      width={200}
      height={50}
    ></canvas>
  );
}
