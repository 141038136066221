export enum Apps {
  "Hydroponic Calculator" = "https://calc.nikitapn.com",
  "Hydroponic Charts" = "https://charts.hydrosoft.uk",
  "Images Converter Web Site" = "https://images.hydrosoft.uk",
  "Images Converter Library" = "https://www.npmjs.com/package/upload-images-converter",
  "Flat Bookmarks Extension" = "https://chromewebstore.google.com/detail/flat-bookmarks/nicflihpgndlmcenkiffocbbielabpgh",
}

export enum AppsDescription {
  "Hydroponic Calculator" = `Hydroponic Calculator, designed with <span>React and C++</span>, combines scientific rigor with a unique interface, featuring celebratory visuals like fireworks, to enhance the user experience. This web app is dedicated to <span>providing precise nutrient calculations</span> for hydroponic enthusiasts. It balances advanced functionality with an engaging, <span>dynamic interface</span>, ensuring both accuracy and an <span>enjoyable</span> user interaction. Users can <span>personalize calculations</span>, input their formulas, and effectively manage solutions. Register for a free Hydrosoft account to fully leverage this innovative tool in your hydroponic gardening journey.`,
  "Hydroponic Charts" = `Hydroponic Charts is an advanced Angular web app designed to <span>complement</span> the Hydroponic Calculator. This tool showcases detailed charts, depicting <span>nutrient solutions</span> suitable for various plants, streamlining the hydroponic gardening process. Its Angular framework facilitates direct interaction with the Hydroponic Calculator, offering users an integrated experience without the need for an account. Whether you're a beginner or a seasoned hydroponic gardener, Hydroponic Charts provides invaluable insights, <span>helping you to optimize nutrient mixes</span> for healthier plant growth.`,
  "Images Converter Web Site" = `<span>Web Images Converter</span>, a sleek React-based web app, is designed with <span>a neomorphic style</span>, offering users a visually <span>pleasing</span> and intuitive interface for image conversion and cropping. This tool efficiently handles various image formats, allowing effortless transformation and customization of your visual content. Images Converter streamlines the process of image resizing, combining the ease of use with <span>modern aesthetics</span>. Perfect for those seeking a quick and reliable solution to refine and adapt their images to <span>different formats</span>.`,
  "Images Converter Library" = `<span>Upload Images Converter</span> is a TypeScript library for client-side image processing, <span>reducing server load</span>. It enables resizing, cropping, and conversion to webp, jpg, png formats <span>before uploading</span>. Features include automated center-focused cropping maintaining proportions, error logging, and customizable settings for size and format. Easy to integrate and use, Upload Images Converter is <span>ideal for streamlining</span> the image processing workflow.`,
  "Flat Bookmarks Extension" = `Discover the elegance of <span>Flat Bookmarks</span>, a Chrome extension that transforms your bookmarking experience. Its sleek <span>glassmorphic design</span>, which adapts to your background, ensures a vibrant and <span>fresh look</span> every time. With Flat Bookmarks, searching and organizing your bookmarks becomes a breeze, all wrapped in a simple, <span>user-friendly interface</span>. Upgrade your browsing with a touch of sophistication and efficiency!`,
}
